/* ********************************
   COPYRIGHT OF PRIME PRESENCE 2024
   ******************************** */

/* CSS styling for the jrrButton component */

.jrr-orange-button {
    display: flex;
    align-items: center;
    border: 1px solid var(--secondarycolor);
    border-radius: 100px;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 15px;
    padding-bottom: 15px;
    cursor: pointer;
    width: fit-content;
}

.jrr-orange-button:hover {
    background-color: var(--secondarycolor);
    color: white;
}

#jrr-button-label {
    margin: 0;
    margin-right: 20px;
    font-size: 20px;
    font-family: "Montserrat", Sans-serif;
    white-space: nowrap;
}

@media (max-width: 850px) {
    #jrr-button-label {
        font-size: 15px;
    }
}