/* ********************************
   COPYRIGHT OF PRIME PRESENCE 2024
   ******************************** */

/* Sidebar.css: Styling for sidebar component */
    .sidebar {
        width: 0;
        height: 100%;
        position: fixed;
        top: 0;
        right: 0; /* Position on the right side */
        background-color: #ffffff;
        overflow-x: hidden;
        transition: 0.5s;
        padding-top: 60px;
        z-index: 100;
        border-radius: 36px 0px 0px 0px;
    }
    
    .sidebar.open {
        width: 250px;
    }
    
    .sidebar ul {
        list-style-type: none;
        padding: 0;
    }
    
    .sidebar ul li {
        padding: 10px;
        color: var(--primarycolor);
    }
    
    .sidebar ul li:hover {
        background-color: var(--secondarycolor);
        color: white;
    }
    
    #close-button-wrapper {
        width: 100%;
    }

    #sidebar-button-close{
        position: relative;
        top: 10px;
        left: calc(100% - 30px);
    }

    
    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
        z-index: 10; /* Ensure it's above other content */
    }