/* ********************************
   COPYRIGHT OF PRIME PRESENCE 2024
   ******************************** */

/* CSS styling for JrrJiggleLink component */

.jrr-jiggle-link-default {
    display: flex;
    align-items: center;
    font-family: "Montserrat", Sans-serif;
    font-size: 17px;
    font-weight: 500;
    line-height: 20px;
    max-width: 240px;
    padding: 5px;
    transition: transform 0.3s ease;
    color: var(--secondarycolor);
}

.jrr-jiggle-link-default:hover {
    cursor: pointer;
    transform: scale(1.2);
    transform: translate(10px,0);
    
}

#jrr-jiggle-link-label {
    margin: 0;
    margin-right: 10px;
    white-space: nowrap;
}

.jrr-jiggle-link-icon {

}

@media (max-width: 850px) {
    .jrr-jiggle-link-default:active #jrr-jiggle-link-label {
        border-bottom: 1px solid var(--secondarycolor);
    }
}