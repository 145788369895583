/* ********************************
   COPYRIGHT OF PRIME PRESENCE 2024
   ******************************** */

/* Holds Global colors and styles */
:root {
  --primarycolor: #17176B;
  --secondarycolor: #FE4F00;
  --lighttext: #B9D9EB;
  --backgroundcolor: #f5fbff;
  --border-radius: 36px 36px 36px 36px;
  --containers-base-size: 1440px;
  --continers-small-size: 40%;
}

.noscroll {
  overflow: hidden;
}
.h1 {
  color: var(--primarycolor)
}

.h2 {
  color: var(--primarycolor);
}

body {
  overflow-y: scroll !important;
  -ms-overflow-style: none !important; /* Hide scrollbar for Internet Explorer and Edge */
  color: var(--primarycolor);
}
body::-webkit-scrollbar {
  display: none !important; /* Hide scrollbar for Chrome, Safari, and Opera */
}

body, html {
  overflow-x: hidden;
}
.content-container {
  width: var(--containers-base-size);
  margin: 0 auto;
}
.no-bold {
  font-weight: 400;
}

.white-background-container {
  background-color: white;
}

.square-image-crop {
  max-height: 640px;
  max-width: 640px;
  width: 50vw;
  height: 50vw;
  overflow: hidden;
}

.square-image-crop img {
  width: 100%;
  height: 100%; /* Ensure the image fills the container */
  object-fit: cover; /* This ensures the image covers the entire container */
  border-radius: var(--border-radius);

}

.logo-crop {

}

.page-container {
  font-family: "Arial", Sans-serif;
  position: relative;
  background-image:
  linear-gradient(to bottom, rgb(255, 255, 255), rgba(255, 255, 255, 0.5)),
  url(/public/Images/background.JPEG);
  background-repeat: no-repeat;
  background-size: cover;

  background-position: bottom;
  background-attachment: fixed;
  background-position: center;
}

/* -----HEADER----- */

.header-container {
  display: flex;
  justify-content: right;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 90px;
  align-items: right;
  
}

.icon-text-wrapper {
  display: flex;
  justify-content: right;
}

.icon-text {
  margin: 0px;
  margin-left: 10px;
  color: var(--primarycolor);
}

.secondary-icon {
  color: var(--secondarycolor);
}



/* -----BODY / First Card----- */

.body-container {
  padding-top: 20px;
  padding-bottom: 20px;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.15);
  border-radius: var(--border-radius);
  background: linear-gradient(180deg, #ffffff, var(--backgroundcolor));
  background-repeat: no-repeat;
}

#body-background {
  position: relative;
}

.body-header-wrapper {

}

.body-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.logo-img{
  width: 133px;
  height: 67px;
}

.words-logo-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.words-logo {
  margin: 0px;
  color: var(--secondarycolor);
  font-weight: 800;
  line-height: 28px;
}

#light-logo-top-word {
  font-size: 200%;
}

#light-logo-bottom-word {
  font-size: 175%;
}

#dark-logo-top-word {
  font-size: 175%;
}

#dark-logo-bottom-word {
  
}

.menu-icon {
  font-size: 30px;
}

.header-links-wrapper {
  display: flex;
  justify-content: left;
  flex-direction: row;
  align-items: center;
  color: var(--primarycolor);
}
.header-link-wrapper {
  display: flex;
  height: 50%;
  align-items: center;
  margin-right: 30px;
}

.header-link-wrapper:hover {
  border-bottom: 2px solid var(--secondarycolor); 
  cursor: pointer;
}

.header-link {
  text-decoration: none;
  padding-right: 5px;
  padding-left: 5px;
  font-size: 20px;
}

#home-link {
  border-bottom: 2px solid var(--secondarycolor); 
}

.body-content {
  display: flex;
  align-items: center;
  padding-top: 40px;
}

.spacer {
}

.img-wrapper-container {
  position: relative;
  border-radius: 10%;

}

.body-img {
  max-width: 710px;
  max-height: 710px;
  object-fit: cover;
  border-radius: 0% 0% 0% 0%;
  -webkit-mask-image: url(/public/Images/body-image-mask.png);
  -webkit-mask-size: contain;
  -webkit-mask-position: center center;
  -webkit-mask-repeat: no-repeat;
}

#body-footer-special-shape {
  z-index: 1;
  position: absolute;
  background-color: var(--backgroundcolor);
  width: 20%;
  height: 80px;
  top: 350px;
  border-radius: 0px 10px 0px 10px;

}

.body-description {
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items: left;
  margin-right: 40px;
  width: 60%;
}

#welcome-text {
  font-family: "Montserrat", Sans-serif;
  font-size: 36px;
  font-weight: 400;
  line-height: 50px;
  color: var(--primarycolor);
  margin-bottom: 0px;
}

.big-header {
  color: var(--primarycolor);
  font-family: "Montserrat", Sans-serif;
  font-size: 70px;
  font-weight: 700;
  line-height: 70px;
  margin-top: 10px;
  margin-bottom: 10px;
}

#tag-line {
  font-family: "Arial", Sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  margin-bottom: 5px;
}

.button-with-image-wrapper {
  display: flex;
  align-items: center;
}

.multi-faces-img {
  height: 65px;
  width: 150px;
  margin-left: 15px;
}

#body-footer-spacer {
  margin-top: 20px;
}

.body-footer {
  display: flex;
  align-items: flex-end;
  padding-bottom: 40px;
  position: relative;
  top: -90px;
}

.body-footer-description {
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 30px;
  padding-right: 30px;
  margin-right: 60px;
  width: 50%;
  background-color: white;
  border-radius: var(--border-radius);
}

.inspiration-slides-wrapper {
  height: 100%;
  justify-content: center;
  align-items: center;
  
}

.inspiration-slides {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100px;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 30px;
  padding-right: 30px;
  width: 35%;
  background-color: white;
  border-radius: var(--border-radius);
  min-width: 276px;
}

.inspiration-static {
  display: flex;
  justify-content: center;
  margin: 0px;
  color: var(--primarycolor);
  font-family: "Montserrat", Sans-serif;
  font-size: 40px;
  font-weight: 400;
  line-height: 46px;
}

.inspiration-changing {
  color: var(--secondarycolor);
  display: flex;
  justify-content: center;
  margin: 0px;
  font-family: "Montserrat", Sans-serif;
  font-size: 40px;
  line-height: 46px;
  animation: pulse 2s linear infinite;

}


@keyframes pulse {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.inspiration-changing:after {
  content: '';  
  animation: spin 10s linear infinite 1s;
}

@keyframes spin {
  0% {
    content: 'innovation';
  }
  20% {
    content: 'integrity';
  }
  40% {
    content: 'collaboration';
  }
  60% {
    content: 'inspiration';
  }
  80% {
    content: 'efficiency';
  }
  100% {
    content: 'innovation';
  }
}

.body-description-text {
  color: var(--primarycolor);
  font-family: "Montserrat", Sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 34px;
}



/* -----ABOUT----- */

.about-container {
  display: flex;
  justify-content: center;
  padding-top: 160px;
  padding-bottom: 160px;
}

.about-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "Montserrat", Sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 28px;
  padding-right: 60px;
  width: 50%;
}

.about-content-paragraph {
  margin: 0px;
  margin-bottom: 20px;
}

.about-img-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

#about-img {
}

.about-title {
  color: var(--primarycolor);
  font-family: "Montserrat", Sans-serif;
  font-size: 55px;
  font-weight: 700;
  line-height: 66px;
  margin: 0px;
  margin-bottom: 30px;
}

#about-jiggle-link {
  margin-top: 20px;
}

/* -----MISSION----- */

.mission-container {
  display: flex;
  justify-content: center;
  padding-bottom: 160px;
}

#mission-background {
  background: linear-gradient(to bottom, white, white 75%, rgba(255, 255, 255, 0));
}

.mission-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "Montserrat", Sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 28px;
  width: 50%;
}

.mission-content-paragraph {
  margin: 0px;
  margin-bottom: 20px;
}

.mission-img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 60px;
}

#mission-img {
}

.mission-title {
  color: var(--primarycolor);
  font-family: "Montserrat", Sans-serif;
  font-size: 55px;
  font-weight: 700;
  line-height: 66px;
  margin: 0px;
  margin-bottom: 30px;
}

#mission-jiggle-link {
  margin-top: 20px;
}



/* -----CONTACT----- */

.contact-section-container {
  display: flex;
  width: var(--containers-base-size);
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 180px;
}

.contact-form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.15);
  border-radius: var(--border-radius);
  background: linear-gradient(180deg, #ffffff, var(--backgroundcolor));
  padding-top: 80px;
  padding-bottom: 80px;
  padding-left: 80px;
  padding-right: 80px;
}

.contact-title {
  color:var(--secondarycolor);
  font-family: "Montserrat", Sans-serif;
  font-size: 55px;
  font-weight: 700;
  line-height: 66px;
  text-align: center;
  margin: 0px;
  margin-bottom: 20px;
}

.contact-subtitle {
  font-family: "Montserrat", Sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  text-align: center;
  margin: 0px;
  margin-bottom: 40px;
}

.contact-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

#extra-label {
  display: none;
}

#extra-input {
  display: none;
}


.contact-label-input-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 800px;
  width: 100%;
  margin-bottom: 10px;
}

.contact-form-label {
  max-width: 760px;
  font-family: "Arial", Sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
}

.contact-form-input {
  max-width: 760px;
  width: 100%;
  height: 40px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 7px;
  padding-bottom: 7px;
  border: none;
  border-bottom: 1px solid black; 
  background-color: var(--backgroundcolor);
  font-family: "Montserrat", Sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 28px;
}

.contact-form-input:focus {
  outline: none;
  border-left: 1px solid rgb(218, 218, 218);
  border-top: 1px solid rgb(218, 218, 218);
  border-right: 1px solid rgb(218, 218, 218);
}

#message {
  resize: vertical;
  min-height: 20px;
}

.contact-submit {
  color: white;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 70px;
  padding-right: 70px;
  background-color: var(--primarycolor);
  border-radius: 100px 100px 100px 100px;
  transition: 0.3s ease;
}

.contact-submit:hover {
  transform: scale(1.15);
  cursor: pointer;
}

#form-error {
  color: red;
}

#form-submitted {
  color: rgb(122, 238, 118);
  font-weight: 600;
}


/* -----FOOTER----- */

.footer-container {
  display: flex;
  flex-direction: column;
  background-color: var(--primarycolor);
  color: white;
  border-radius: 36px 36px 0px 0px;
}

.footer-body-spacer {
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 80px;
  padding-bottom: 80px;
}

.footer-body {
  display: flex;
  justify-content: space-between;
  margin-left: 5%;
  margin-right: 5%;
}

.footer-contact {
  font-family: "Montserrat", Sans-serif;
}

.footer-contact-title {
  margin: 0px;
  margin-bottom: 20px;
  color:var(--lighttext);
  font-size: 24px;
  font-weight: 600;
  line-height: 42px;
}

.contact-line {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  
}

.contact-line-text {
  margin: 0px;
  margin-left: 10px;
}

.footer-footer {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.copyright {
  margin: 0px;
  font-size: 10px;
  font-weight: 400;
  font-style: normal;
  line-height: 22px;
}

@media (max-width: 1234px) {
  .body-footer {
    top: -2vw;
  }
}

@media (max-width: 1010px) {
  .body-footer {
    top: 0px;
  }
}

/* -----SMALL SCREEN ---- */
@media (max-width: 1490px) {
  .content-container {
    width: var(--containers-small-size);
    margin-left: 20px;
    margin-right: 20px;
  }

  .body-img {
    width: 100%;
  }

  .contact-section-container {
    width: var(--containers-small-size);
    margin-left: 20px;
    margin-right: 20px;
  }
  
}

/* -----MOBILE----- */

@media (max-width: 850px) {
  .square-image-crop {
    max-height: 640px;
    max-width: 640px;
    width: 80vw;
    height: 80vw;
    overflow: hidden;
  }
  .body-header{
    padding: 0px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .logo-img{
    width: 133px;
    height: 67px;
  }
  .body-content {
    flex-direction: column;
    padding: 0px;
    padding-top: 40px;
  }
  .body-description {
    margin: 0px;
    margin-bottom: 20px;
    width: 90%;
  }
  #welcome-text {
    font-size: 1.2em;
    line-height: normal;
    margin: 0px;
    margin-bottom: 10px;
  }
  .multi-faces-img {
    width: 40%;
    max-width: 150px;
    margin: 0px;
  }

  .big-header {
    font-size: 2.2em;
    line-height: normal;
    margin-top: 10px;
    margin-bottom: 0px;
  }

  #tag-line {
    font-size: 1em;
  }

  .body-img {
    -webkit-mask-image: none;
    border-radius: 10%;
  }

  .body-footer {
    flex-direction: column;
    align-items: center;
    padding: 0px;
  }
  .body-footer-description{
    margin: 0px;
    padding-left: 2%;
    padding-right: 2%;
    padding-top: 25px;
    padding-bottom: 25px;
    width: 100%;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .inspiration-slides {
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }
  .inspiration-static {
    font-size: 2em;
  }
  .inspiration-changing {
    font-size: 2em;
  }

  .about-container {
    flex-direction: column;
    padding-top: 40px;
    padding-bottom: 40px;
    align-items: center;
  }
  .about-content {
    padding: 0px;
    width: 100%;
    margin-bottom: 40px;
  }
  .about-title {
    font-size: 2em;
    line-height: normal;
    margin-bottom: 15px;
  }
  .about-img-container {
    width: 90%;
    margin-bottom: 40px;
  }

  .mission-container {
    flex-direction: column;
    padding-top: 40px;
    padding-bottom: 40px;
    align-items: center;
  }
  .mission-content {
    padding: 0px;
    width: 100%;
    margin-bottom: 40px;
  }
  .mission-title {
    font-size: 2em;
    line-height: normal;
    margin-bottom: 15px;
  }
  .mission-img-container {
    width: 90%;
    padding: 0px;
    margin-bottom: 40px;
  }

  .contact-section-container {
    margin: 0px;
    margin-bottom: 40px;
  }
  .contact-form-container {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .contact-label-input-wrapper {
    width: 85%;
    padding-left: 0px;
    padding-right: 0px;
  }

  .contact-title {
    font-size: 2em;
    line-height: normal;
  }
  .contact-subtitle{
    font-size: 1.2em;
    line-height: normal;
  }
  .contact-form-label {
    font-size: 1em;
  }
  .contact-form-input {
    font-size: 1em;
    padding-left: 5%;
    padding-right: 5%;
    width: auto;
  }

  .footer-body-spacer {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 50px;
    padding-bottom: 20px;
  }

  .footer-body {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0px;
  }
  .footer-contact-title {
    text-align: center;
  }
  .footer-contact {
    margin-bottom: 10px;
  }
  .contact-line {
    justify-content: center;
    text-align: center;
  }

  .footer-footer {
    margin: 0px;
  }

}

